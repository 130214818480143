import { template as template_58fe6935ff9f49cc9947c45c72d4c8bd } from "@ember/template-compiler";
const FKText = template_58fe6935ff9f49cc9947c45c72d4c8bd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
