import { template as template_53aeac566e454a0889f228946cccb9ea } from "@ember/template-compiler";
const FKLabel = template_53aeac566e454a0889f228946cccb9ea(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
